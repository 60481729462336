import React, { Fragment } from 'react';
import './App.css';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

import Landing from './components/core/Landing';
import ProtectedPage from './components/core/ProtectedPage';
// import GapYear from './components/writings/GapYear';
// import Leadership from './components/writings/Leadership';
// import Seed from './components/writings/Seed';
// import Zage from './components/writings/Zage';
import Angel from './components/core/Angel';
// import Wallets from './components/writings/Wallets';
// import Scale from './components/writings/Scale';
// import Feedback from './components/other/solana/Feedback';

import ReactGA from 'react-ga';
import { createBrowserHistory } from 'history';

const history = createBrowserHistory();

// Initialize google analytics page view tracking
history.listen((location) => {
  ReactGA.set({ page: location.pathname }); // Update the user's current page
  ReactGA.pageview(location.pathname); // Record a pageview for the given page
});

function App() {
  return (
    <Fragment history={history}>
      <Router>
        <Fragment>
          <div id='content'>
            <Switch>
              <Route exact path='/' component={Landing} />
              {/* <Route exact path='/gap' component={GapYear} /> */}
              {/* <Route exact path='/gap-year'>
                <Redirect to='/gap' />
              </Route> */}
              {/* <Route exact path='/leadership' component={Leadership} /> */}
              {/* <Route exact path='/seed' component={Seed} /> */}
              {/* <Route exact path='/zage' component={Zage} /> */}
              <Route exact path='/protected' component={ProtectedPage} />
              <Route exact path='/angel' component={Angel} />
              {/* <Route exact path='/scale' component={Scale} /> */}
              {/* <Route exact path='/mobile-wallets' component={Wallets} /> */}
            </Switch>
          </div>
        </Fragment>
      </Router>
    </Fragment>
  );
}

export default App;
