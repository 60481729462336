import React from 'react';
import '../../App.css';
import X from '../../assets/images/X_logo.svg';
import home from '../../assets/images/home_logo.svg';
import substack from '../../assets/images/substack_logo.svg';
import linkedin from '../../assets/images/linkedin_logo.svg';
import goodreads from '../../assets/images/goodreads_logo.svg';

const Angel = () => {
  return (
    <div className='app'>
      <header className='header'>
        <h1>Kelvin Yu</h1>
        <nav>
          <ul className='nav-links'>
            <li>
              <a href='/' target='_blank' rel='noopener noreferrer'>
                <img
                  src={home}
                  alt='Home'
                  style={{ height: '1.2em', width: 'auto' }}
                />
              </a>
            </li>
            <li>
              <a
                href='https://twitter.com/kelvinotcelsius'
                target='_blank'
                rel='noopener noreferrer'
              >
                <img
                  src={X}
                  alt='X'
                  style={{ height: '1.2em', width: 'auto' }}
                />
              </a>
            </li>
            <li>
              <a
                href='https://kelvinotcelsius.substack.com'
                target='_blank'
                rel='noopener noreferrer'
              >
                <img
                  src={substack}
                  alt='Substack'
                  style={{ height: '1.2em', width: 'auto' }}
                />
              </a>
            </li>
            <li>
              <a
                href='https://www.linkedin.com/in/kelvinotcelsius/'
                target='_blank'
                rel='noopener noreferrer'
              >
                <img
                  src={linkedin}
                  alt='Linkedin'
                  style={{ height: '1.2em', width: 'auto' }}
                />
              </a>
            </li>
            <li>
              <a
                href='https://www.goodreads.com/kelvinotcelsius'
                target='_blank'
                rel='noopener noreferrer'
              >
                <img
                  src={goodreads}
                  alt='Goodreads'
                  style={{ height: '1.2em', width: 'auto' }}
                />
              </a>
            </li>
          </ul>
        </nav>
      </header>
      <main>
        <section className='intro'>
          <p>
            I am occasionally fortunate to support founders on their journey.
            Below are select startups I've invested in, either through my
            personal angel fund, private syndicate, or as a scout on behalf of
            Bain Capital Ventures.
          </p>
        </section>

        <section className='investments'>
          <h2>Portfolio</h2>
          <table className='investments-table'>
            <thead>
              <tr>
                <th>Company</th>
                <th>Description</th>
                <th>Other Investors</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <a
                    href='https://www.atomic.industries/'
                    target='_blank'
                    rel='noopener noreferrer'
                  >
                    Atomic Industries
                  </a>
                </td>
                <td>Automated tool and die-making</td>
                <td>Narya, 8VC</td>
              </tr>
              <tr>
                <td>
                  <a
                    href='https://varda.com/'
                    target='_blank'
                    rel='noopener noreferrer'
                  >
                    Varda Space
                  </a>
                </td>
                <td>Microgravity manufacturing</td>
                <td>Founders Fund</td>
              </tr>
              <tr>
                <td>
                  <a
                    href='https://www.causal.app/'
                    target='_blank'
                    rel='noopener noreferrer'
                  >
                    Causal
                  </a>
                </td>
                <td>Next-gen tools for FP&A</td>
                <td>Accel, Coatue</td>
              </tr>
              <tr>
                <td>
                  <a
                    href='https://www.joinpropel.com/'
                    target='_blank'
                    rel='noopener noreferrer'
                  >
                    Propel
                  </a>
                </td>
                <td>Financial OS for low-income Americans</td>
                <td>a16z, Kleiner Perkins</td>
              </tr>
              <tr>
                <td>
                  <a
                    href='https://getmagic.com/'
                    target='_blank'
                    rel='noopener noreferrer'
                  >
                    Magic
                  </a>
                </td>
                <td>On-demand assistants</td>
                <td>Sequoia</td>
              </tr>
              <tr>
                <td>
                  <a
                    href='https://www.jerichosecurity.com/'
                    target='_blank'
                    rel='noopener noreferrer'
                  >
                    Jericho Security
                  </a>
                </td>
                <td>Anti-AI phishing security</td>
                <td>Lux Capital, Era</td>
              </tr>
              <tr>
                <td>
                  <a
                    href='https://holocron.tech/'
                    target='_blank'
                    rel='noopener noreferrer'
                  >
                    Holocron
                  </a>
                </td>
                <td>Global technology awareness</td>
                <td>New North Ventures</td>
              </tr>
              <tr>
                <td>
                  <a
                    href='http://www.turing.com/'
                    target='_blank'
                    rel='noopener noreferrer'
                  >
                    Turing
                  </a>
                </td>
                <td>Top 1% dev talent</td>
                <td>Founders Fund</td>
              </tr>
              <tr>
                <td>
                  <a
                    href='http://finnihealth.com/'
                    target='_blank'
                    rel='noopener noreferrer'
                  >
                    Finni Health
                  </a>
                </td>
                <td>Infra for autism care providers</td>
                <td>General Catalyst</td>
              </tr>
              <tr>
                <td>
                  <a
                    href='https://www.truenorthfleet.com/'
                    target='_blank'
                    rel='noopener noreferrer'
                  >
                    True North
                  </a>
                </td>
                <td>Full-stack freight carrier</td>
                <td>Sam Altman, Lachy Groom</td>
              </tr>
              <tr>
                <td>
                  <a
                    href='https://www.gptzero.me/'
                    target='_blank'
                    rel='noopener noreferrer'
                  >
                    GPTZero
                  </a>
                </td>
                <td>The anti-GPT</td>
                <td>Footwork, Uncork Capital</td>
              </tr>
              <tr>
                <td>
                  <a
                    href='https://wagr.us/'
                    target='_blank'
                    rel='noopener noreferrer'
                  >
                    Wagr (acquired)
                  </a>
                </td>
                <td>Social sports betting app</td>
                <td>776 Ventures, Pear Ventures</td>
              </tr>
              <tr>
                <td>
                  <a
                    href='https://sivo.com/'
                    target='_blank'
                    rel='noopener noreferrer'
                  >
                    Sivo
                  </a>
                </td>
                <td>Embedded lending</td>
                <td>Maple VC, A Typical VC</td>
              </tr>
              {/* <tr>
                <td>
                  <a
                    href='https://www.chiper.co/'
                    target='_blank'
                    rel='noopener noreferrer'
                  >
                    Chiper
                  </a>
                </td>
                <td>Alibaba for Latam</td>
                <td>JAM Fund</td>
              </tr> */}
              <tr>
                <td>
                  <a
                    href='https://www.elysiumhealth.com/'
                    target='_blank'
                    rel='noopener noreferrer'
                  >
                    Elysium
                  </a>
                </td>
                <td>NAD+ supplements</td>
                <td>General Catalyst</td>
              </tr>
              {/* <tr>
                <td>
                  <a
                    href='https://infobot.ai/'
                    target='_blank'
                    rel='noopener noreferrer'
                  >
                    Infobot
                  </a>
                </td>
                <td>AI-generated news</td>
                <td>Y Combinator, Rebel Fund</td>
              </tr> */}
              <tr>
                <td>
                  <a
                    href='https://hellosalted.com/'
                    target='_blank'
                    rel='noopener noreferrer'
                  >
                    Salted
                  </a>
                </td>
                <td>Virtual restaurant platform</td>
                <td>Craft Ventures, Creadev</td>
              </tr>
              <tr>
                <td>
                  <a
                    href='https://pathao.com/'
                    target='_blank'
                    rel='noopener noreferrer'
                  >
                    Pathao
                  </a>
                </td>
                <td>Superapp for ASEAN</td>
                <td>Anchorless Partners, Gojek</td>
              </tr>
              <tr>
                <td>
                  <a
                    href='http://ekhodealer.com/'
                    target='_blank'
                    rel='noopener noreferrer'
                  >
                    Ekho Dealer
                  </a>
                </td>
                <td>Enabling D2C for OEMs</td>
                <td>Riverpark Capital, Westcott Group</td>
              </tr>
            </tbody>
          </table>
        </section>
      </main>
    </div>
  );
};

export default Angel;
