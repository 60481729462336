import React from 'react';
import '../../App.css';
import X from '../../assets/images/X_logo.svg';
import investments from '../../assets/images/investments_logo.svg';
import substack from '../../assets/images/substack_logo.svg';
import linkedin from '../../assets/images/linkedin_logo.svg';
import goodreads from '../../assets/images/goodreads_logo.svg';

function App() {
  return (
    <div className='app'>
      <header className='header'>
        <h1>Kelvin Yu</h1>
        <nav>
          <ul className='nav-links'>
            <li>
              <a
                href='https://twitter.com/kelvinotcelsius'
                target='_blank'
                rel='noopener noreferrer'
              >
                <img
                  src={X}
                  alt='X'
                  style={{ height: '1.2em', width: 'auto' }}
                />
              </a>
            </li>
            <li>
              <a
                href='https://kelvinotcelsius.substack.com'
                target='_blank'
                rel='noopener noreferrer'
              >
                <img
                  src={substack}
                  alt='Substack'
                  style={{ height: '1.2em', width: 'auto' }}
                />
              </a>
            </li>
            <li>
              <a
                href='https://www.linkedin.com/in/kelvinotcelsius/'
                target='_blank'
                rel='noopener noreferrer'
              >
                <img
                  src={linkedin}
                  alt='Linkedin'
                  style={{ height: '1.2em', width: 'auto' }}
                />
              </a>
            </li>
            <li>
              <a
                href='https://www.goodreads.com/kelvinotcelsius'
                target='_blank'
                rel='noopener noreferrer'
              >
                <img
                  src={goodreads}
                  alt='Goodreads'
                  style={{ height: '1.2em', width: 'auto' }}
                />
              </a>
            </li>
            <li>
              <a href='/angel' target='_blank' rel='noopener noreferrer'>
                <img
                  src={investments}
                  alt='Investments'
                  style={{ height: '1.2em', width: 'auto' }}
                />
              </a>
            </li>
          </ul>
        </nav>
      </header>
      <main>
        <section className='intro'>
          <p>I'm building something new.</p>
          <p>
            I've been an investor at IQT, tech policy advisor in Congress, 2x
            founding engineer, and Princeton dropout.
          </p>
          <p>
            Outside my immediate work, I enjoy competitive athletics, ancient
            Chinese dramas, and organizing reading groups. Current topics of
            interest include: 3D reconstruction, historicity of major religions,
            and solutions to the Böckenförde dilemma. Reach out if you'd like to
            trade notes:{' '}
            <code className='email'>
              \".\".join([\"moc\",\"liamg@lanosrep.uy.nivlek\"])[::-1]
            </code>
          </p>
        </section>
        <hr className='section-divider' />
        <section className='essays'>
          <h2>Projects & Writing</h2>
          <ul>
            <li>
              <a
                href='http://rebuilding.tech/'
                target='_blank'
                rel='noopener noreferrer'
              >
                Rebuilding America: The Techno-Industrial Policy Playbook
              </a>
            </li>
            <li>
              <a
                href='https://kelvinotcelsius.substack.com/p/atomic-transactions-where-is-my-flying'
                target='_blank'
                rel='noopener noreferrer'
              >
                Atomic Transactions: Why Some Deeptech Categories Fail More Than
                Others
              </a>
            </li>
            <li>
              <a
                href='https://kelvinotcelsius.substack.com/p/agentic-theories-of-history-and-the'
                target='_blank'
                rel='noopener noreferrer'
              >
                Anti-Agentic Theories of History: The False Seduction of
                Cyclicality
              </a>
            </li>
            <li>
              <a
                href='https://kelvinotcelsius.substack.com/p/notes-from-the-underground-founder'
                target='_blank'
                rel='noopener noreferrer'
              >
                Notes from the Underground Founder: Silicon Valley
                Superfluousness
              </a>
            </li>
            <li>
              <a
                href='https://americancompass.org/unleash-the-techno-industrial-state/'
                target='_blank'
                rel='noopener noreferrer'
              >
                Unleash the Techno-Industrial State
              </a>{' '}
              <i>(published in American Compass)</i>
            </li>
            <li>
              <a
                href='https://www.leversforprogress.com'
                target='_blank'
                rel='noopener noreferrer'
              >
                Levers for Progress: How States Can Advance Science and
                Technology
              </a>
            </li>
          </ul>
        </section>
      </main>
    </div>
  );
}

export default App;
